<template>
  <div class="flex items-center justify-center overflow-hidden">
    <canvas ref="canvas"
            class=""
    />
  </div>
</template>

<script setup lang="ts">
import {
  onMounted,
  onUnmounted, ref,
  computed,
  watch,
  type ComputedRef
} from 'vue'
import type { WatchStopHandle } from 'nuxt/dist/app/compat/capi'
import { useScreenSize } from '../../composables/use-screen-size'

interface ImageSequenceProps {
  progress: number
}

const START_FRAME = 1
const END_FRAME = 240
const SEQUENCE_NUMBER_LENGTH = 3
const FILE_SUFFIX = '.png'

const props = defineProps<ImageSequenceProps>()
const { isMobile } = useScreenSize()
const canvas = ref(null)
const frames: ComputedRef<Array<HTMLImageElement>> = computed(getFrames)

let context: CanvasRenderingContext2D
let stopWatchingProgress: WatchStopHandle

onMounted(() => {
  context = canvas.value.getContext('2d')
  getFrames()
})

onUnmounted(() => {
  if (stopWatchingProgress) stopWatchingProgress()
})

function getFrames() {
  const images: Array<HTMLImageElement> = []
  const sequencePath = isMobile.value ? '/img-sequence/mobile/' : '/img-sequence/desktop/'
  const fileName = isMobile.value ? 'mobile_' : 'desktop_'
  let framesLoaded = 0

  for (let i = START_FRAME; i <= END_FRAME; i++) {
    const image = new Image()
    image.src = sequencePath + fileName + padWithZeroes(i, SEQUENCE_NUMBER_LENGTH) + FILE_SUFFIX
    image.addEventListener('load', () => {
      framesLoaded++
      if (framesLoaded > END_FRAME - START_FRAME) onFramesLoaded()
    })
    images[i] = image
  }

  return images
}

function onFramesLoaded() {
  stopWatchingProgress = watch(() => props.progress, progress => {
    const frameIndex = getCurrentFrameIndex(progress)
    requestAnimationFrame(() => renderFrame(frameIndex))
  })

  renderFrame(getCurrentFrameIndex(props.progress))
}

function getCurrentFrameIndex(progress: number) {
  return Math.min(END_FRAME, Math.ceil(progress * END_FRAME + 1))
}

function renderFrame(currentFrame: number) {
  if (!canvas.value) return

  // ratio and canvas width is calculated based on image dimensions
  const ratio = isMobile.value ? 870 / 357 : 1181 / 1440

  canvas.value.width = isMobile.value ? window.innerWidth : 1440
  canvas.value.height = isMobile.value ? window.innerHeight : 1181

  context.clearRect(0, 0, canvas.value.width, canvas.value.height)
  context.drawImage(frames.value[currentFrame], 0, 0, canvas.value.width, canvas.value.width * ratio)
}

function padWithZeroes(number: number, length: number) {
  let paddedNumber = '' + number
  while (paddedNumber.length < length) {
    paddedNumber = '0' + paddedNumber
  }
  return paddedNumber
}
</script>
